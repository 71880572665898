.App {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

 #spinner {
border: 10px solid #f3f3f3;
border-top: 10px solid #2e3551;
border-radius: 50%;
width: 40px;
height: 40px;
animation: spin 1s linear infinite;

 }

 .MuiNativeSelect-standard {
  padding-left: 5px !important;
 }

 div.MuiDataGrid-menu > div.MuiPaper-elevation > ul.MuiList-root > li:last-child {
  display: none !important;

 }

 ul#side-bar-list li:last-child {
   display: block !important;;
 }

 